<template>
    <section class="section four" id="gallery">
        <h2>{{ $t('section4.h2')}}</h2>
        <div class="dash"></div>
        <p>{{ $t('section4.p')}}</p>

        <div class="awards">
            <img src="../assets/awards/award2017.jpg" alt="" draggable="false">
            <img src="../assets/awards/award2021.jpg" alt="" draggable="false">
            <img src="../assets/awards/award2022.jpg" alt="" draggable="false">
            <img src="../assets/awards/award2022eu.jpg" alt="" draggable="false">
        </div>

    </section>
</template>

<script>
export default {
  name: 'Section4.vue',
  props: {
    msg: String
  },
  methods: {
    
  }
}
</script>

<style scoped>

section.four {
  margin-top: 40px;
  height: fit-content;
}

section.four h2{
    font-size: 40px;
    margin: 100px 0 20px 0;
    width: 90vw;
    text-align: center;
}

section.four p{
    font-size: 18px;
    margin: 20px;
    width: 90vw;
    text-align: center;
}

section.four .gallery {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-content: center;
}

section.four .gallery img {
    width: 30vw;
    border-radius: 5%;
    margin: 10px;
}

section.four .awards {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-content: center;
    margin: 20px 0 100px 0;
}

section.four .awards img {
    width: 15vw;
    border-radius: 5%;
    margin: 10px;
}

/* section.four .gallery img:hover {
    opacity: 60%;
} */



/* ANIMATIONS */

/* .section.four img:hover {
    animation: shakeABit 0.5s ease-in-out;
} */

.section.four h2 {
    animation: bounce 2s ease;
  }

.bounce {
    animation: bounce 2s ease;
  }

/* @keyframes shakeABit {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    30% { transform: translate(2px, 2px) rotate(0deg); }
    70% { transform: translate(2px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  } */

  @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
      40% {transform: translateY(-30px);}
      60% {transform: translateY(-15px);}
  }



@media (max-width: 790px) {

    section.four {
        height: 100%;
        text-align: center;
    }

    /* gallery */
    section.four .gallery {
        display: flex;
        flex-direction: column;
    }

    section.four .gallery img {
        width: 90vw;
    }

}

</style>