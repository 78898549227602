<template>
    <section class="section five" id="about">
        <h2>{{ $t('section5.h2')}}</h2>
        <div class="dash"></div>
        <h3>{{ $t('section5.h3')}}</h3>
        <h4>{{ $t('section5.h4')}}</h4>
        <p>{{ $t('section5.p1')}}</p>
        <p>{{ $t('section5.p2')}}</p>
        <p>{{ $t('section5.p3')}}</p>
        <p>{{ $t('section5.p4')}}</p>
        <p>{{ $t('section5.p5')}}</p>
        <p>{{ $t('section5.p6')}}</p>
        <br>
        <br>
        <h5>{{ $t('section5.h5')}}</h5>
    </section>
</template>

<script>
export default {
  name: 'Section5.vue',
  props: {
    msg: String
  }
}
</script>

<style scoped>

.section.five {
    margin: 40px 0;
    height: fit-content;
}

.section.five h2 {
    font-size: 40px;
}

section.five h3 {
    font-size: 25px;
    margin: 20px 0 10px 0;
    max-width: 90vw;
    text-align: center;
}

section.five h4 {
    font-size: 20px;
    margin-bottom: 20px;
    max-width: 90vw;
    text-align: center;
}

section.five p {
    width: 90vw;
    text-align: center;
    font-size: 18px;
}

section.five h5 {
    font-size: 20px;
    margin-bottom: 20px;
    max-width: 90vw;
    text-align: center;
}


/* ANIMATIONS */

.section.five h2 {
    animation: slideInFromLeft 1s ease-in;
}

.section.five h3 {
    animation: slideInFromRight 1s ease-in;
}

.section.five h4 {
    animation: slideInFromLeft 1s ease-in;
}

.section.five h5 {
    animation: slideInFromBottom 1s ease-in;
}


@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateX(0);
    }
}



@media (max-width: 790px) {

    section.five {
        height: 100%;
        padding: 0 10px;
    }

    section.five h2 {
        margin-top: 40px;
    }

    section.five h3 {
        margin-top: 40px;
        text-align: center;
    }

    section.five h4 {
        text-align: center;
    }

    section.five h5{
        padding: 0 10px;
        text-align: center;
    }

    section.five p {
        font-size: 15px;
        text-align: left;
    }

}

</style>