<template>
  <div class="langSwitcher">
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'langSwitcher',
  data () {
    return { langs: ['de', 'en'] }
  }
}
</script>

<style>
    .langSwitcher {
    padding: 3px;
    position: absolute;
    top: 10px;
    right: 20px;
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 18px;
    font-weight: 600;
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  cursor: pointer;
  text-transform: uppercase;
}

select:focus {
  outline: none;
}

</style>