<template>
    <section class="section one">
        <div class="div-logo"><img class="logo" src="../assets/logos/logo2.png" alt="logo" draggable="false"></div>
        <h1 v-html="$t('section1.h1')"></h1>
    </section>

</template>

<script>
export default {
  name: 'Section1.vue',
  props: {
    msg: String
  },
};

</script>

<style scoped>

section.one {
    background-image: url("../assets/panino_light.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 70%;
    position: relative;
}

section.one .div-logo {
    width: 100%;
    height: 150px;
    position: absolute;
    display: flex;
    top: 20px;
    justify-content: center;
    z-index: 1;
}

section.one .logo {
    height: 150px;
    position: absolute;
    justify-content: center;
    justify-self: end;
    justify-items: center;
    /* top: 40px;
    left: 44%; */
}



/* ANIMATIONS */
.section.one h1 {
    animation: slideInFromLeft 1s ease-in;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}





@media (max-width: 790px) {

    .div-logo {
        width: 80vw;
        height: 150px;
        display: flex;
        justify-content: center;
        z-index: 10000;
    }

    .logo {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    section.one {
        background-size: cover;
        background-position: center;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    section.one h1 {
        width: 80vw;
        text-align: center;
        font-size: 32px;

        text-align: left;
        position: absolute;
        left: 40px;
        justify-content: center;
        /* color: rgb(206, 90, 44); */
        color: #fff;
        text-shadow: black 1px 1px;
    }

}

@media (max-height: 520px) {
    section.one .logo {
    height: 100px;
    }
}

@media (max-height: 420px) {
    section.one h1 {
        font-size: 24px;
    }
}
</style>