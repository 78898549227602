<template>
    <footer id="contact">
        <div class="media-icons">
            <a href="https://www.facebook.com/porcobradobadenbaden" target="blank"><i class="fab fa-facebook"></i></a>
            <a href="https://www.instagram.com/porcobrado_de" target="blank"><i class="fab fa-instagram"></i></a>
        </div>
        
        <!-- Footer German -->
        <p>&copy; Porcobrado {{ new Date().getFullYear() }} - {{ $t('footer.p')}}</p>

        <div class="bottom-footer">
            <div class="address">
                <p>Waldseestraße 2a 76530</p>
                <p>Baden-Baden</p>
            </div>

            <div class="opening-hours">
                <p>{{ $t('footer.p2')}}</p>
                <p>{{ $t('footer.p3')}}</p>
            </div>
        </div>

    </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

footer {
    width: 100vw;
    height: 250px;
    background-color: #ff9114;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}

footer a {
    color: #fff;
    font-size: 25px;
    margin-left: 10px;
}

footer a:hover {
    color: #000;
    transition-delay: 0.1s;
}

footer p {
    font-size: 15px;
}

.bottom-footer {
    margin: 30px auto;
    width: 60vw;
    display: flex;
    justify-content: space-between;
    text-align: center;
}

@media (max-width: 665px) {
    .bottom-footer {
        margin: 30px auto;
        width: 95vw;
        display: flex;
        justify-content: space-between;
    }
}

@media (max-width: 463px) {
    .address{
        text-align: left;
    }

    .opening-hours{
        text-align: right;
    }
}

</style>
