<template>
  <header class="hello">
    <nav>
      <div class="toggleMenu" @click="toggleMenu();"></div>
      <ul class="navigation">
          <li v-if="homePage()"><router-link to="/">Home</router-link></li>          
          <li><a href="/#panini">{{ $t('nav.ul.li.panini')}}</a></li>
          <li><a href="/#gallery">Porcobrado Deutschland</a></li>
          <li><a href="/#about">{{ $t('nav.ul.li.about')}}</a></li>
          <li><a href="/#contact">{{ $t('nav.ul.li.contact')}}</a></li>
          <li v-if="impressumPage()"><router-link to="/impressum">Impressum</router-link></li>
      </ul>
      <!-- <button class="language-selector">{{ $t('nav.language-selector')}}</button> -->
      <LangSwitcher class="langswitcher" @change="updateLanguage()" />
    </nav>
  </header>
  <!-- <TourModal v-show="showTourModal" @close-modal="showTourModal = false; showTourDatesModal = true; setSession();" /> -->
  <!-- <TourDatesModal v-show="showTourDatesModal" @close-modal="showTourDatesModal = false" /> --> 
</template>

<script>
import LangSwitcher from './LangSwitcher.vue'
// import TourModal from './TourModal.vue'   // MODAL
// import TourDatesModal from './TourDatesModal.vue'  // MODAL

export default {
  components: { 
                LangSwitcher
                // ,  // MODAL
                // TourModal,  // MODAL
                // TourDatesModal  // MODAL
              },
  name: 'Header',
  props: {
    msg: String
  },  
  data() {
    return {
      // showTourModal: true,    // MODAL
      // showTourDatesModal: false,  // MODAL
    }
  },
  beforeMount(){
    let data = sessionStorage.getItem("visited");
    if(data == 'yes') {
      // this.showTourModal = false; // MODAL
      // console.log("vero");
    }
  },
  mounted() {
    if (sessionStorage.getItem("locale")) {
      this.$i18n.locale = sessionStorage.getItem("locale");
    } else {
      sessionStorage.setItem("locale", this.$i18n.locale);
    }
  },
  methods: {
    setSession(){ sessionStorage.setItem("visited", "yes")},
    updateLanguage() {
      sessionStorage.setItem("locale", this.$i18n.locale);
    },
    // Navbar toggle
   toggleMenu(){
    const toggleMenu = document.querySelector('.toggleMenu');
    const navigation = document.querySelector('.navigation');
    toggleMenu.classList.toggle('active');
    navigation.classList.toggle('active');
    navigation.addEventListener('click', () => {
        navigation.classList.remove('active')
        toggleMenu.classList.remove('active')
    })
   },
   // Conditional Rendering of Home button in the nav bar 
   homePage() {
        if(this.$route.path == "/") {
          return false
        } else {
          return true
        }
    },
   // Conditional Rendering of Impressum button in the nav bar
   impressumPage() {
        if(this.$route.path == "/impressum") {
          return false
        } else {
          return true
        }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
* {
    box-sizing: border-box;   
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

header .navigation.active{
    position: fixed;
    display: flex;
    max-height: 100vh;
}

nav {
    /* background-color: #364547; */
    background-color: #ff9114;
    width: 100vw;
    padding: 15px 30px;
    display: flex;
    justify-content: center;
}

nav ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 1000px;
}

nav ul li {
    list-style: none;
    margin-left: 30px;
}

nav a {
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.8px;
    font-size: 18px;
}

nav a:hover {
    border-bottom: 2px solid #fff;
    transition-delay: 0.1s;
}

/* .language-selector {
    padding: 3px;
    position: absolute;
    top: 10px;
    right: 20px;
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 18px;
    font-weight: 600;
} */

.save-btn {
  justify-content: center;
  display: flex;
}

/* ANIMATIONS */
nav li{
    animation: shake 1s;
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }



@media (max-width: 940px) {

 .langswitcher {
  z-index: 100;
 }

    header .navigation {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #ff9114;
        z-index: 10;
        display: none;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 30px;
        padding-right: 25px;
        width: 100%;
        height: 100vh;
        min-height:fit-content;
        overscroll-behavior: auto contain;
    }

    .toggleMenu{
        position: relative;
        width: 30px;
        height: 30px;
        background: url(../assets/icons/menu.png);
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        z-index: 11;
    }

    .toggleMenu.active{
        background: url(../assets/icons/close.png);
        background-position: center;
        position: fixed;
        background-size: 25px;
        background-repeat: no-repeat;
    }

    nav ul{
      padding-top: 70px;
    }

    header .navigation.active {
      background: url(../assets/logos/logo2.png);
      background-position: top;
      background-position-y: 20%;
      background-size: 250px;
      background-repeat: no-repeat;
      background-color: #ff9114;
    }
}

@media (max-width:550px), screen and (max-height: 640px) {
    header .navigation.active {
      background-size: 200px;
    }
}

@media (max-height: 520px) {
    header .navigation.active {
      background-size: 150px;
    }
}

@media (max-height: 450px) {
    header .navigation.active {
      background-size: 100px;
      background-image: none;
    }
}

/* @media (max-height: 380px) {
    header .navigation.active {
      background-size: 50px;
    }
} */


</style>
