<template>
<Header />
  <div class="container">
    <router-view />
  </div>
<Footer />
</template>

<script>
  import Header from './components/Header.vue'
  import Footer from './components/Footer.vue'

  export default {
    name: 'App',
    components: {
      Header,
      Footer,
    }
  }
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');


* {
    box-sizing: border-box;   
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

body {
    background-color: #efedd6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    overflow-x: hidden;
}

h1 {
    font-size: 50px;
    width: 450px;
    text-align: left;
    position: absolute;
    left: 40px;
    /* color: rgb(206, 90, 44); */
    color: #fff;
    text-shadow: black 1px 1px;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    /* transform: translateX(400%);
    transition: transform 0.4s ease; */
}

.dash {
    width: 200px;
    border: 2px solid black;
    border-radius: 50%;
    margin-top: 10px;
}



/* @MEDIA QUIRIES */

@media (max-width: 790px) {

    * {
        overflow-x: hidden;
        /* padding: 0 10px; */
    }


}


</style>
