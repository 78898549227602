<template>
    <section class="section two" id="panini"> 
        <h2>{{ $t('section2.h2')}}</h2>
        <div class="dash"></div>

        <div class="features">
            <div class="feature fone">
                <img src="../assets/icons/pig.png" alt="">
                <h3>{{ $t('section2.features.feature1.h3')}}</h3>
                <p>{{ $t('section2.features.feature1.p')}}</p>
            </div>

            <div class="feature ftwo">
                <img src="../assets/icons/made-with-love.png" alt="">
                <h3>{{ $t('section2.features.feature2.h3')}}</h3>
                <p>{{ $t('section2.features.feature2.p')}}</p>
            </div>

            <div class="feature fthree">
                <img src="../assets/icons/trophy.png" alt="">
                <h3>{{ $t('section2.features.feature3.h3')}}</h3>
                <p>{{ $t('section2.features.feature1.p')}}</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'Section2.vue',
  props: {
    msg: String
  }
}
</script>

<style scoped>

section.two {
    display: flex;
    flex-direction: column;
    /* background: linear-gradient(360deg, #ffdd9e 0%, #c67700 100%); */
    background: linear-gradient(360deg, #7e7e7e 0%, #363636 100%);
    color: #ff9114;
    overflow: hidden;
}

section.two h2 {
    font-size: 40px;
}

section.two .dash {
    width: 200px;
    border: 2px solid #ff9114;
    border-radius: 50%;
    margin-top: 10px;
}

section.two .features {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    padding: 30px;
    width: 90vw;
    justify-content: center;
}

section.two .features .feature {
    width: 1fr;
    text-align: center;
    padding: 20px;
}

section.two .features .feature i {
    width: 60px;
    height: 60px;
}

section.two .features .feature img {
    width: 60px;
    height: 60px;
    margin: 15px 0;
}


section.two .features .feature h3 {
    font-size: 25px;
}

section.two .features .feature p {
    font-size: 18px;
}



/* ANIMATIONS */

.section.two h2 {
    animation: slideInFromTop 1s ease-in;
}

.section.two .fone{
    animation: slideInFromLeft 1s ease-in;
}

.section.two .ftwo{
    animation: shake 1s;
}

.section.two .fthree{
    animation: slideInFromRight 1s ease-in;
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}



@media (max-width: 790px) {
    
    section.two {
        height: 100%;
        padding: 30px 0;
    }

    section.two h2 {
        margin-top: 40px;
    }

    section.two .features {
        display: flex;
        flex-direction: column;
        padding: 0;
    }

}

@media (max-width: 840px) {
    
    section.two {
        height: 100%;
        min-height: fit-content;
        padding: 30px 0;
    }
}


</style>