<template>
  <div class="impressum">
    <h3>Anbieter</h3>
    <p>
      Porcobrado Deutschland wird betrieben von der Gesellschaft Lukaj UG
      (haftungsbeschränkt)
    </p>
    <p>Waldseestraße 2 a - 76530 Baden-Baden</p>

    <br />

    <h3>Vertreten durch:</h3>
    <p>Lukaj UG</p>
    <p>Geschäftsführender Gesellschafter: Tiljaus Lukaj</p>
    <p>Gesellschafter: Armando Zorba</p>

    <br />

    <h3>Kontakt:</h3>
    <p>Tel.+49(0)7223/9193037</p> <!-- new number-->
    <p>Mail: Porcobrado.de@gmail.com</p>
    <p>Instagram: @porcobrado_de</p>

    <br />

    <h3>Registrierung:</h3>
    <p>Sitz in Baden-Baden</p>
    <p>Eintragung im Handelsregister.</p>
    <p>Registergericht: Amtsgericht Mannheim</p>
    <p>Registernummer: HRB 737214</p>

    <br />

    <h3>Umsatzsteuer:</h3>
    <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:</p>
    <p>Ust-IdNr.: DE334074689</p>

    <br />

    <h3>Angaben zur Betriebshaftpflichtversicherung:</h3>
    <p>Name & Sitz des Versicherers:</p>
    <p>HDI Versicherung</p>

    <br />

    <h3>Streitschlichtung:</h3>
    <p>
      Die Europäische Kommission stellt eine Plattform zur
      Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr.
    </p>
    <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
    <p>
      Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren von
      einer Verbraucherschlichtungsstelle teilzunehmen.
    </p>

    <br />

    <h3>Fotografie</h3>
    <p>Porcobrado SRL cortona</p>
    <p>und eigene</p>

    <br />

    <h3>Koordination und Redaktion:</h3>
    <p>Lukaj UG</p>
    <p>Abteilung Marketing</p>

    <br />

    <h3>Realisierung</h3>
    <p>
      Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für
      die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind
      ausschließlich deren Betreiber verantwortlich.
    </p>
    <p>
      Urheberrecht und gewerbliche Schutzrechte Texte, Bilder, Grafiken, Klänge,
      Musik, Animationen und Videos sowie deren Anordnung unterliegen dem Schutz
      des Urheberrechtes und anderer Schutzgesetze. Die Vervielfältigung,
      Abänderung, Übertragung oder Veröffentlichung des Inhaltes dieser Website
      auch in Teilen ist, außer zu privaten, nicht kommerziellen Zwecken, in
      jeglicher Form untersagt. Alle auf dieser Website enthaltenen Kennzeichen
      (geschützte Marken, wie Logos und geschäftliche Bezeichnungen) sind
      Eigentum von Porcobrado oder Dritter und dürfen ohne vorherige
      schriftliche Einwilligung nicht verwendet, dauerhaft heruntergeladen,
      kopiert oder verbreitet werden.
    </p>
  </div>
</template>

<style scoped>
  .impressum{
    max-width: 100vw;
    padding: 50px;
  }

  @media (max-width: 600px) {
    .impressum{
    max-width: 100vw;
    padding: 30px;
  }
  }
</style>