{
    "nav": {
        "ul": {
            "li": {
                "panini" : "Unsere Panini",
                "about" : "Über uns",
                "contact" : "Kontakt"
            }
        },
        "language-selector": "EN"
    },
    
    "section1": {
      "h1": " 100% Weidehaltung <br> 100% Toskana <br> Wir sind stolz auf unsere Tradition"
    },
  
    "section2": {
      "h2" : "Unsere Panini",
      "features": {
        "feature1": {
          "h3": "Freilandschweine",
          "p": "Die Schweine werden nur artgerecht gehalten. Sie leben frei in unseren Wäldern und bewahren damit ihre einzigartige Qualität"
        },
        "feature2":{
          "h3": "Mit Liebe gemacht",
          "p": "Wir freuen uns, daß wir das in Cortona behütete Verarbeitungsrezept weitergeben dürfen"
        },
        "feature3":{
          "h3": "Preisgekröntes Produkt",
          "p": "Es ist das einzige Produkt, das als das beste europäische belegte Brötchen prämiert wurde (EuroSFA 2017 – Berlin)"
        }
      }
    },
  
    "section3": {
      "p": ""
    },
  
    "section4": {
      "h2": "Panino Porcobrado - das Nr. 1 Streetfood aus Italien" ,
      "p": "Unsere Titelgewinne  beim European Streetfood Award in Italien und beim Europa-Finale: Bestes Streetfood in Italien 2017 - 2022 und Bestes Sandwich in Europa 2017/ Berlin + 2022/ München"
    },

    "section5": {
      "h2": "Über uns",
      "h3": "100% FLEISCH AUS CORTONA",
      "h4": "GEBOREN UND AUFGEWACHSEN IN CORTONA",
      "p1": "Unseres toskanischen Brötchens, das nicht imitiert werden kann, ist gefüllt mit Schweinefleisch der freilebenden Rasse 'Maiale di Cinta Senese DOP'.  Aufzucht und Verarbeitung der Schweine findet in Cortona - Toskana statt.",
      "p2": "Diese toskanischen Schweine werden seit über 100 Jahren artgerecht gehalten. In den letzten 20 Jahren haben wir uns auf die Zucht der Rasse 'Cinta senese' und 'Grigio chianino' spezialisiert. Die Tiere leben frei in unserem 200 Hektar großen Wald. Wir sind die größten Halter dieser alten italienischen Rasse.",
      "p3": "Seit 2016 sind wir in die Welt des street food eingetreten. Wir bieten ein einzigartiges Produkt freilebender Tiere, die sich aus biologischen Produkten ernähren, die auf unserem Gutshof produziert werden.",
      "p4": "Das Brötchen 'Porcobrado' erhält seinen außergewöhnlichen Geschmack durch das lange Räuchern mit Kirschbaumholz auf niedriger Temperatur.",
      "p5": "Nach großem Erfolg in Italien wurde es als das 'beste eurpäische belegte Brötchen' auf der Berliner Messe EuroSFA-im Jahre 2017 prämiert.",
      "p6": "Durch den Titelgewinn als 'Bestes Streetfood in Italien' hat Porcobrado im Finale des European Streetfood Awards Italien vertreten. Im Jahr 2022 hat Porcobrado zum zweiten Mal nach 2017 den Titel als das 'Beste Sandwich in Europa' beim European Streetfood Award gewonnen.",
      "h5": "KOMMEN SIE VORBEI! UND PROBIEREN SIE DEN UNTERSCHIED!"
    },
  
    "footer": {
      "p" : "Alle Rechte vorbehalten",
      "p2" : "Das Restaurant in der Shopping Cité in",
      "p3" : "Baden-Baden ist dauerhaft geschlossen"
    } 
  }