{
  "nav": {
      "ul": {
          "li": {
              "panini" : "Our Panini",
              "about" : "About us",
              "contact" : "Contact"
          }
      },
      "language-selector": "DE"
  },
  
  "section1": {
    "h1": "Proudly made with Free Range Italian Pigs from Tuscany"
  },

  "section2": {
    "h2" : "Our Panini",
    "features": {
      "feature1": {
        "h3": "Free Range Pigs",
        "p": "Our pigs are happy pigs because they can run, play and eat in total freedom"
      },
      "feature2":{
        "h3": "Made with Love",
        "p": "Our Panini are made following our century old tradition with a secret touch that makes them truly unique"
      },
      "feature3":{
        "h3": "Award Winning Product",
        "p": "Our product is so unique that it was awarded best sandwich of Europe (EuroSFA 2017 – Berlin)"
      }
    }
  },

  "section3": {
    "p": ""
  },

  "section4": {
    "h2":"Panino Porcobrado - the No. 1 street food from Italy",
    "p": "Winner at the European Streetfood Award in Italy and at the European final: Best Street Food in Italy 2017 - 2022 and Best Sandwich in Europe 2017/ Berlin + 2022/ Munich"
  },

  "section5": {
    "h2": "About us",
    "h3": "100% CORTONA MEAT",
    "h4": "BORN, RAISED AND WORKED IN CORTONA",
    "p1": "Our Tuscan bread roll, which cannot be imitated, is filled with pork of the wild breed 'Maiale di Cinta Senese DOP'. The pigs are raised and processed in Cortona - Tuscany.",
    "p2": "We have been raising pigs in Cortona, Tuscany for over 100 years. In the past 20 years we have specialised in rasing Cinta Senese and Grigio Chianino pigs, free to live outdoors and run across our 200 hectares of woods. We are the biggest breeders of ancient breeds in Italy.",
    "p3": "Since 2016 we have entered the street food world with a unique product made with free animals that eat organic products grown on our farm.",
    "p4": "Our Porcobrado sandwich is the result of marinating, smoking with cherry wood and a long low temperature cooking of the meat.",
    "p5": "After the great success in Italy, it was declared the best sandwich of Europe at the Berliner Messe EuroSFA in 2017.",
    "p6": "By winning the title of 'Best Street Food in Italy', Porcobrado represented Italy in the final of the European Street Food Awards. In 2022, Porcobrado won the title of 'Best Sandwich in Europe' at the European Street Food Awards for the second time after 2017.",
    "h5": "COME AND TRY THE DIFFERENCE!"
  },

  "footer": {
    "p" : "All Rights Reserved",
    "p2" : "The restaurant in the Shopping Cité in",
    "p3" : "Baden-Baden is permanently closed"
  } 
}